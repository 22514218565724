import { RemovableRef, useStorage } from '@vueuse/core'
import { defineStore } from 'pinia'
import jwt_decode from 'jwt-decode'

export interface ISimAuthorizationState {
  token: string | null | RemovableRef<null>
}

export const useSimAuthorization = defineStore('simIdentity', {
  state: (): ISimAuthorizationState => ({
    token: useStorage('sim-token', null)
  }),
  actions: {
    setToken(token: string) {
      this.token = token
    },
    clearToken() {
      this.token = null
    }
  },
  getters: {
    getToken: state => () => state.token,
    getData: state => () => {
      return jwt_decode(state.token as string)
    },
    isLoggedIn: state => !!state.token
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSimAuthorization, import.meta.hot))
}
